import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'PlayFlow features roadmap',
      paragraph: "From the time of just launching a free tool to make the lives of game developers easier to now being a feature packed robust product, we are excited to share what we've been working on at PlayFlow and what we're looking forward to ahead. Here's a quick look."
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="November 2021">
                Deployed a high-quality first release of PlayFlow and conducted a market validation test
              </TimelineItem>
              <TimelineItem title="February 2022">
                Launched simplified pricing servers with regional reverse proxy and server monitoring
              </TimelineItem>
              <TimelineItem title="March 2022">
                Gathered feedback and made quality of life improvements to the PlayFlow API and Unity Plugin
              </TimelineItem>
              <TimelineItem title="June 2022">
                Conducted closed-beta test with game studios and developers for on-demand auto-scaling global servers 
              </TimelineItem>
              <TimelineItem title="July 2022">
                Deployed a high-quality first release of auto-scaling servers
              </TimelineItem>
              <TimelineItem title="August 2022">
                Begin work on a no-code / low-code match-maker that integrates directly into Unity and further simplifies the game development process
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;