import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Don\'t take our word for it',
      paragraph: 'Here\'s some feedback from our users & game studios'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>


            <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Making multiplayer games is hard. PlayFlow has allowed us to iterate faster which has drastically helped us test.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Fireside</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://studiofireside.com">Fireside Studio</a>
                    </span>
                  </div>
                </div>
              </div>


              <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — PlayFlow provided me with a free way to test my multiplayer game, which was useful for me since it was my first time trying out multiplayer. The creator of PlayFlow is also very responsive on Discord and really takes time to help you if you run into any problems.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Spontalis</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://assetstore.unity.com/packages/tools/network/playflow-cloud-206903#reviews">Spontalis</a>
                    </span>
                  </div>
                </div>
              </div>
{ 
              <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Me and my team were looking for a fast way to deploy servers that were used for testing and developing our project. And Playflow helped us with that! Very intuitive and simple to use. Helped us immensely! 
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">gdmavroeidis</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://assetstore.unity.com/packages/tools/network/playflow-cloud-206903#reviews">gdmavroeidis</a>
                    </span>
                  </div>
                </div>
              </div> }


{ 
 }

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;