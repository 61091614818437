import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false
}

class Pricing extends React.Component {

  state = {
    priceChangerValue: "1",
    priceInput: {
      // 0: "1,000",
      // 1: "1,250"
    },
    priceOutput: {
      plan1: {
        0: ["Free"],
        1: ["Free"]
      },
      plan2: {
        0: ["Studio"],
        1: ["Studio"]
      },
      plan3: {
        0: ["Enterprise"],
        1: ["Enterprise"]
      },
      plan4: {
        0: ["Pro"],
        1: ["Pro"]
      }, 
      plan5: {
        0: ["Advanced"],
        1: ["Advanced"]
      },
      plan6: {
        0: ["Production"],
        1: ["Production"]
      }          
    }
  }

  slider = React.createRef();
  sliderValue = React.createRef();

  componentDidMount() {
    if (this.props.pricingSlider) {
      this.slider.current.setAttribute('min', 0);
      this.slider.current.setAttribute('max', Object.keys(this.state.priceInput).length - 1);
      this.thumbSize = parseInt(window.getComputedStyle(this.sliderValue.current).getPropertyValue('--thumb-size'), 10);
      this.handleSliderValuePosition(this.slider.current);
    }
  }

  handlePricingSwitch = (e) => {
    this.setState({ priceChangerValue: e.target.checked ? '1' : '0' });
  }

  handlePricingSlide = (e) => {
    this.setState({ priceChangerValue: e.target.value });
    this.handleSliderValuePosition(e.target);
  }

  handleSliderValuePosition = (input) => {
    const multiplier = input.value / input.max;
    const thumbOffset = this.thumbSize * multiplier;
    const priceInputOffset = (this.thumbSize - this.sliderValue.current.clientWidth) / 2;
    this.sliderValue.current.style.left = input.clientWidth * multiplier - thumbOffset + priceInputOffset + 'px';
  }

  getPricingData = (values, set) => {
    return set !== undefined ? values[this.state.priceChangerValue][set] : values[this.state.priceChangerValue];
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Say hello to simple pricing.',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} tag="h1" className="center-content reveal-from-bottom" />
            {/* {pricingSwitcher &&
              <div className="pricing-switcher center-content reveal-from-top">
                <Switch
                  checked={this.state.priceChangerValue === '1' ? true : false}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Billed Annually">
                  Billed Monthly
                </Switch>
              </div>
            }
            {pricingSlider &&
              <div className="pricing-slider center-content reveal-from-top">
                <label className="form-slider">
                  <span className="mb-16">How many users do you have?</span>
                  <input
                    type="range"
                    ref={this.slider}
                    defaultValue={this.state.priceChangerValue}
                    onChange={this.handlePricingSlide}
                  />
                </label>
                <div ref={this.sliderValue} className="pricing-slider-value">
                  {this.getPricingData(this.state.priceInput)}
                </div>
              </div>
            }             */}
            <div className={tilesClasses}>

              {<div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-after h3">
                          {this.getPricingData(this.state.priceOutput.plan1, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan1, 1)}
                        </span>
                        <span className="pricing-item-price-after text-sm">
                          {this.getPricingData(this.state.priceOutput.plan1, 2)}
                        </span>
                      </div>
                      <div className="text-xs text-color-medium">
                      The quickest and easiest way to spin up a free dedicated server for your game development.
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title h3 text-xs text-color-high mb-24">
                       $0 per month
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">1-Click server hosting</li>
                        <li className="is-checked">Unlimited concurrent users</li>
                        <li className="is-checked">Built-in Reverse Proxy</li> 
                        <li className="is-checked">1-hour session limit</li>
                        <li className="is-checked">Server monitoring</li>
                        <li className="is-checked">1 Temporary server</li> 
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="https://app.playflowcloud.com">Get Started</Button>
                  </div>
                </div>
              </div> }

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-after h3">
                          {this.getPricingData(this.state.priceOutput.plan2, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan2, 1)}
                        </span>
                        <span className="pricing-item-price-after text-sm">
                          {this.getPricingData(this.state.priceOutput.plan2, 2)}
                        </span>
                      </div>
                      <div className="text-xs text-color-medium">
                        All you need to launch your game, increase productivity, and keep your players connected in a scalable fashion.
                       </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h3 text-xs text-color-high mb-24">
                       $9.99 per month
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">All the benefits of Free</li>
                        <li className="is-checked">No session limits</li>
                        <li className="is-checked">Advanced server profiling</li>
                        <li className="is-checked">Dedicated servers</li>
                        <li className="is-checked">1 CPU Core</li> 
                        <li className="is-checked">1GB RAM</li> 
                        <li className="is-checked">2TB Bandwidth</li> 
                        <li className="is-checked">Built-in Reverse Proxy for multiple servers</li> 
                  
                        {/* <li className="is-checked">Server Monitoring (Beta)</li> */
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="https://app.playflowcloud.com">Get Started</Button>
                  </div>
                </div>
              </div>

              {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-after h3">
                          {this.getPricingData(this.state.priceOutput.plan4, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan4, 1)}
                        </span>
                        <span className="pricing-item-price-after text-sm">
                          {this.getPricingData(this.state.priceOutput.plan4, 2)}
                        </span>
                      </div>
                      <div className="text-xs text-color-medium">
                        Easily scale your games using high performance instances with increased RAM and bandwidth.
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h3 text-xs text-color-high mb-24">
                       $14.99 per month
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">All the benefits of Studio</li>
                        <li className="is-checked">No session limits</li>
                        <li className="is-checked">Advanced server profiling</li>
                        <li className="is-checked">Dedicated servers</li> 
                        <li className="is-checked">1 CPU Core</li> 
                        <li className="is-checked">2GB RAM</li> 
                        <li className="is-checked">3TB Bandwidth</li> 
                        <li className="is-checked">Built-in Reverse Proxy for multiple servers</li> 

                  

                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="https://app.playflowcloud.com">Get Started</Button>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-after h3">
                          {this.getPricingData(this.state.priceOutput.plan5, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan5, 1)}
                        </span>
                        <span className="pricing-item-price-after text-sm">
                          {this.getPricingData(this.state.priceOutput.plan5, 2)}
                        </span>
                      </div>
                      <div className="text-xs text-color-medium">
                        For large-scale games that require high performance memory and multiple processors.
                       </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h3 text-xs text-color-high mb-24">
                       $24.99 per month
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">All the benefits of Pro</li>
                        <li className="is-checked">No session limits</li>
                        <li className="is-checked">Advanced server profiling</li>
                        <li className="is-checked">Dedicated servers</li> 
                        <li className="is-checked">2 CPU Cores</li> 
                        <li className="is-checked">4GB RAM</li> 
                        <li className="is-checked">4TB Bandwidth</li> 
                        <li className="is-checked">Built-in Reverse Proxy for multiple servers</li> 

                  
                    
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="https://app.playflowcloud.com">Get Started</Button>
                  </div>
                </div>
              </div> */}

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-after h3">
                          {this.getPricingData(this.state.priceOutput.plan6, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan6, 1)}
                        </span>
                        <span className="pricing-item-price-after text-sm">
                          {this.getPricingData(this.state.priceOutput.plan6, 2)}
                        </span>
                      </div>
                      <div className="text-xs text-color-medium">
                        For auto-scaling games that need servers on demand at a global scale. Spin up your game servers anywhere in the world as needed.</div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h3 text-xs text-color-high mb-24">
                       Starts at $30 per month
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Unlimited CCU</li>
                        <li className="is-checked">No session limits</li>
                        <li className="is-checked">Advanced server profiling</li>
                        <li className="is-checked">Dedicated servers of any type</li> 
                        <li className="is-checked">On demand Global Servers</li> 
                        <li className="is-checked">Built-in Global Reverse Proxy</li> 
                  
                        {/* <li className="is-checked">Server Monitoring (Beta)</li> */
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="https://app.playflowcloud.com">Get Started</Button>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-left" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-after h3">
                          {this.getPricingData(this.state.priceOutput.plan3, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan3, 1)}
                        </span>
                        <span className="pricing-item-price-after text-sm">
                          {this.getPricingData(this.state.priceOutput.plan3, 2)}
                        </span>
                      </div>
                      <div className="text-xs text-color-medium">
                      PlayFlow Cloud for the most demanding game studios and teams—all the flexibility to meet your game's requirements.
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                    {<div className="pricing-item-features-title h3 text-xs text-color-high mb-24">
                       Get in touch
                      </div>}
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                      <li className="is-checked">All the benefits of Production</li>
                        <li className="is-checked">Flexible bandwidth</li>
                        <li className="is-checked">Features tailored to your needs</li>
                        <li className="is-checked">Custom server configuration</li>
                        <li className="is-checked">Scalable servers</li> 
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="https://discord.gg/P5w45Vx5Q8">Contact Us</Button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;