import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import GenericSection from '../components/sections/GenericSection';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';
import Pricing from '../components/sections/Pricing';


class Home extends React.Component {

  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Multiplayer Game Development Simplified',
      paragraph: 'It\'s challenging enough to develop a multiplayer game. Take the hassle of server hosting away by using our one-click server hosting tool built into Unity.'
    }

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <GenericSection topDivider className="center-content">
          <SectionHeader data={genericSection01Header} className="reveal-from-bottom" />
          <div className="reveal-from-bottom">
            <a
              data-video="https://www.youtube.com/embed/URhGmWZDWOQ"
              href="#0"
              aria-controls="video-modal"
              onClick={this.openModal}
            >              
              <Image
                src={require('./../assets/images/thumbnail.png')}
                alt="Video"
                width={712}
                height={400} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={this.state.videoModalActive}
            handleClose={this.closeModal}
            video="https://www.youtube.com/embed/URhGmWZDWOQ?autoplay=1"
            videoTag="iframe" />
        </GenericSection>
        {/* <Pricing className="illustration-section-02" />          */}
        <FeaturesTiles topDivider className="center-content" />
        <Testimonial topDivider />  
        <Cta split />
      </React.Fragment>
    );
  }
}

export default Home;