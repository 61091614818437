import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import Pricing from '../components/sections/Pricing';
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  

    const genericSection01Header = {
      title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    } 

    const genericSection02Header = {
      title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection03Header = {
      title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    }   

    const genericSection04Header = {
      title: 'FAQ - Everything you need to know so you can use PlayFlow like a pro.'
    }

    return (
      <React.Fragment>
        
        <Pricing topDivider pricingSwitcher className="illustration-section-02" />
        {/* <Team topDivider /> */}

        {/* <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Lorem ipsum is placeholder text commonly used in the graphic.</h2>
            <p>
              Lorem ipsum dolor sit amet, <a href="#0">consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/image-placeholder.svg')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">A super-nice image <span role="img" aria-label="mile">😀</span></figcaption>
            </figure>
            <h4>Flexibility</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
            </ul>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
            </p>
          </div>
        </GenericSection> */}

        {/* <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            <div className="center-content">
              <Button
                color="primary"
                aria-controls="demo-modal"
                onClick={this.openModal}>Open modal</Button>
            </div>
            <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Join our newsletter</h3>
                <p className="text-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
                </p>
              </div>
              <form style={modalFormStyle}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label="This is a label"
                    placeholder="Your best email"
                    labelHidden />
                </div>
                <Button color="primary" wide>Subscribe</Button>
              </form>
              <div className="center-content mt-24">
                <a
                  className="text-xxs fw-500 tt-u"
                  aria-label="close"
                  href="#0"
                  onClick={this.closeModal}
                >No thanks!</a>
              </div>
            </Modal>
          </div >
        </GenericSection>         

        <GenericSection topDivider className="center-content">
          <div className="container">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <ButtonGroup>
              <Button color="primary" wideMobile>Get started now</Button>
              <Button color="secondary" wideMobile>Get started now</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button color="dark" wideMobile>Get started now</Button>
              <Button wideMobile>Get started now</Button>
            </ButtonGroup>
          </div>
        </GenericSection> */}

        {/* <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection03Header} className="center-content" />
            <form style={formStyle}>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden>
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden
                  value="hello@cruip.com">
                  <Button color="primary" loading>Early access</Button>
                </Input>
              </div>              
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden
                  status="error"
                  hint="Something is wrong.">
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden
                  status="success"
                  hint="You've done it.">
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
            </form>
          </div>
        </GenericSection>        */}

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>

            <AccordionItem title="What multiplayer frameworks are supported on PlayFlow Cloud?">
              PlayFlow Cloud supports all multiplayer frameworks that support dedicated server builds from Unity such as Mirror, Mirage, DarkRift 2, FishNet, etc.
              </AccordionItem>


            {/* <AccordionItem title="How does the free developer plan work?">
                The free plan allows a developer to spin up only one game server on PlayFlow Cloud at any given time. Once a game server is published, the server will be up for 60 minutes. After 60 minutes, the server goes offline and the game server will need to be published again.  
              </AccordionItem> */}

              <AccordionItem title="Do I need to wait 60 minutes to publish a server again on the free plan?">
                Nope. Feel free to publish as many times as you like!
              </AccordionItem>

              <AccordionItem title="Where can I find more information about plans for PlayFlow Cloud?">
              Check out the plan types section on PlayFlow Cloud's documentation: https://docs.playflowcloud.com/fundamentals/plan-types
              </AccordionItem>
             
              <AccordionItem title="How many times can I publish a server to PlayFlow Cloud?">
                With PlayFlow Cloud, you can publish your game server as many times as you like regardless of which plan you're using.
              </AccordionItem>
              <AccordionItem title="Does PlayFlow Cloud save any game data?">
                PlayFlow Cloud does not save any game server data and deletes your game server's temporary data after the server 60 minute time-limit.
              </AccordionItem>
              <AccordionItem title="PlayFlow Cloud sounds awesome! How do you profit from free temporary servers?">
                The goal of PlayFlow Cloud's free plan is to make multiplayer game development more accessible to everyone in the world. Free temporary servers are intended to be a tool for development testing and play testing. There really are no hidden surprises here, the free plan is not intended to make a profit.
              </AccordionItem>
              <AccordionItem title="When will PlayFlow Cloud be out of beta?">
                While we can't give an exact date on when we will be out of beta, we have been running performance tests with many users and are developing new features every day. Join the discord for more real-time updates!
              </AccordionItem>
              <AccordionItem title="What is the pricing model for the Production Plan?">
                The Production plan is a pay as you grow model. It starts at $30 per month and includes $30 worth of servers in the plan that can be created around the world on demand. When the total price of all the servers that were started exceeds $30, server price will be calcualed per server. The pricing for each server that is started depends on the type of server that is started and the duration that it runs. Here is a quick look at the current pricing info:
                <li>Instance Types</li>
                <li> Small - $0.015 per hour</li>
                <li> Medium - $0.03 per hour</li>
                <li> Large - $0.06 per hour</li>
              </AccordionItem>

              

              {/* <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem> */}
            </Accordion>
          </div>
        </GenericSection>        

        {/* <Roadmap topDivider /> */}
        <Cta split />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}

export default Secondary;